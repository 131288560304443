import { configureStore } from '@reduxjs/toolkit';
import linktreReducer from '../features/linkTreeSlice';
import authReducer from '../features/auth/authSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    linktree: linktreReducer,
  },
});