import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage


const initialState = {
    user: null,
    userState: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


// Register new user
export const register = createAsyncThunk(
    'auth/register',
    async (user, thunkAPI) => {
      try {
        const userCredential = await authService.register(user);
        // Extract only serializable data from the user object
        const userData = {
          uid: userCredential.user.uid,
          email: userCredential.user.email,
          displayName: userCredential.user.displayName,
          // ... any other data you need
        };
        return userData;

      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message)
        return thunkAPI.rejectWithValue(message)
      }
    }
  )


// Login new user
export const login = createAsyncThunk(
    'auth/login',
    async (user, thunkAPI) => {
        
      try {
        return await authService.login(user)
      } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  )
  

// Logout user
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      await authService.logout();
      return null; // Return null to indicate no user
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);





export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      setDarkModeRedux: (state, action) =>{
        state.dark_mode = action.payload
      },
      setUser: (state, action) => {
    if (action.payload) {
      // Extract and set only the serializable user data
      state.user = {
        uid: action.payload.uid,
        email: action.payload.email,
        token: action.payload.token,
        // ... any other fields you need
      };
    } else {
      state.user = null; // No user is signed in
    }
  },
      reset: (state) =>{
        state.isLoading = false
        state.isError = false
        state.message = ''
        state.isSuccess = false
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(register.pending, (state) => {
          state.isLoading = true
        })
        .addCase(register.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(register.rejected, (state, action) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(login.pending, (state) => {
          state.isLoading = false
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(logout.pending, (state) => {
          state.isLoading = false
        })
        .addCase(logout.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            // state.user = null 
        })
        .addCase(logout.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })       
    },
  })
  
  export const {reset, setDarkModeRedux, setUser} = authSlice.actions
  export default authSlice.reducer