import {AiOutlineYoutube} from 'react-icons/ai'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getLinkTreeData} from '../../features/linkTreeSlice';
import {MdDeleteForever, MdHeight} from 'react-icons/md'
import {AiFillEdit} from 'react-icons/ai'
import {deleteLinkTreeData, updateLinkTree} from '../../features/linkTreeSlice';
import CreateLink from './CreateLink';
import { HOST_URL } from '../../config';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';


const obtenerPrimerSegmento = (pathname) => {
    const segmentos = pathname.split('/');
    return segmentos[1]; // El primer segmento después del primer '/'
};

function LinkInBio() {

    const [linksEng, setLinksEng] = useState([]);
    const [linksEsp, setLinksEsp] = useState([]);
    const [edit, setEdit] = useState(false);
    const [selectedLink, setSelectedLink] = useState(null);
    const [spanish, setSpanish] = useState(false);
    const location = useLocation();
    const refer = obtenerPrimerSegmento(location.pathname);



    const { linkTreeEng, linkTreeEsp } = useSelector((state) => state.linktree);
    const {user} = useSelector ((state) => state.auth);

   

    const dispatch = useDispatch();

    // ACTIONS
     // delete 
     const deleteLink = (id) => {
        if(user){
            let confirm = window.confirm('Are you sure you want to delete?')
            if(confirm){
                dispatch(deleteLinkTreeData(id))
                .unwrap()
                .then(() => {
                    dispatch(getLinkTreeData())
                })
                .catch((error) => {
                    toast.error(error)
                })
            }
        }
     }

    //  edit

    const [formData, setFormData] = useState({
        text: null,
        linkChange: null,
        utm: null,
        position_id: null,
    });

    const { text, linkChange, utm, position_id } = formData;
      

      const onChange = (e) => {

        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      };


    const changeLinkTree = (id, e) => {
        if(user){
            e.preventDefault();
        const updateData = {
            ...(text && { text: text }),
            ...(linkChange && { link: linkChange }),
            ...(utm && { utm: utm }),
            ...(position_id && { position: position_id })
        };
        const data = {
            docId: id,
            updateData
         }

         dispatch(updateLinkTree(data))
         .unwrap()
         .then(() => {
            dispatch(getLinkTreeData())
            setSelectedLink(null)
            setFormData({
                text: null,
                linkChange: null,
                utm: null,
                position_id: null,
              });
         })
        }
    }


    //  END ACTIONS

    useEffect(() => {
        dispatch(getLinkTreeData())
    }, [])


    useEffect(() => {
        let eng = [];
        let esp = [];

        if(linkTreeEng && linkTreeEsp && linkTreeEng.length > 0 && linkTreeEsp.length > 0){
            linkTreeEng.forEach(element => {
                if(element.section === 'link'){
                    eng.push(element)
                }
            });
            setLinksEng(eng);

            linkTreeEsp.forEach(element => {
                if(element.section === 'link'){
                    esp.push(element)
                }
            });
            setLinksEsp(esp);
        }
    }, [linkTreeEng, linkTreeEsp])


    const social_values = {
        INSTAGRAM_ENG: 'linktree_ig_eng',
        INSTAGRAM_ES: 'linktree_ig_es',
        TIKTOK: 'linktree_tt',
    }

    const refer_values = {
        en: 'en',
        es: 'es',
        tt: 'tt',
    }   


    const [social, setSocial] = useState('')




    useEffect(() => {
        if(refer){
            if(refer === refer_values.en){
                setSocial(social_values.INSTAGRAM_ENG)
            } else if (refer === refer_values.es){
                setSocial(social_values.INSTAGRAM_ES)
            } else if (refer === refer_values.tt){
                setSocial(social_values.TIKTOK)
            }
        

        }
    },[refer])




    if(refer && (refer === refer_values.en || refer === refer_values.tt)){
        return (
            <div className="container-bio">
                <div className="bio-header"> 
                    <img src="aulart-logo-white-1.svg" alt="" className='bio-logo'/>
                </div>
                {(linkTreeEng && linkTreeEng.length > 0 && linksEng && linksEng.length > 0) && (
                    <>
                        <div className="bio-titles">
                            <h1 className="bio-subtitle">{linkTreeEng[0].title}</h1>
                            <div className="" style={{textAlign:'center'}}>
                                <a href="https://apps.apple.com/us/app/aulart/id6476809730"><img src="iosavailable.png" alt="Download App" style={{ height: '130px'}} /></a>
                            </div>
                            <p className="bio-paragraph">Dowload now to get Free Content</p>
                        </div>
                        {user && <CreateLink language={'eng'}/>}
                        <div className="links-bio">
                        {linksEng.sort((a, b) => a.position - b.position).map((link, index) => (
                                <>                                  
                                    <div className='link-bio'>
                                        {user && (
                                            <div className="link-actions">
                                                <MdDeleteForever size={28} className='delete-icon' onClick={() => deleteLink(link.id)}/>
                                                <AiFillEdit size={28} className='edit-icon' onClick={selectedLink !== index ? () => setSelectedLink(index) : () => setSelectedLink(null)}/>
                                            </div>
                                        )}
                                            <a href={`${link.link}?utm_medium=social&utm_source=${social ? social : 'error'}&utm_campaign=${link.utm}`} target="_blank">
                                                <img className='image-bio-link' src={link.gcs?link.gcs:(HOST_URL +'images/'+ link.image)}  alt={link.text} />
                                                {selectedLink === index ? (
                                                    <div className='edit-link-container'>
                                                        <input onClick={(e) => e.preventDefault()} id='text' name='text' value={text} onChange={onChange} type="text" className="input-form-center" placeholder={link.text} />
                                                        <input style={{maxWidth: '100px'}} onClick={(e) => e.preventDefault()} id='utm' name='utm' value={utm} onChange={onChange} type="text" className="input-form-center" placeholder={link.utm} />
                                                        <input onClick={(e) => e.preventDefault()} id='linkChange' name='linkChange' value={linkChange} onChange={onChange} type="text" className="input-form-center" placeholder={link.link} />
                                                        <input onClick={(e) => e.preventDefault()} style={{maxWidth: '50px'}} type='text' placeholder={link.position} id='position_id' name='position_id' value={position_id} onChange={onChange} className="input-form-center" required />
                                                        <button onClick={(e) => changeLinkTree(link.id, e)} type="submit" className="btn btn-reverse btn-title">SAVE</button>
                                                    </div>
                                                ) : (
                                                    <p>{link.text}</p>
                                                )}
                                            
                                        </a>
                                        
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="social-media">
                            <a href="https://www.youtube.com/channel/UCL_R5xbrMzxB_9PlW4jyZFA" target="_blank"> <AiOutlineYoutube size={33} className='bio-icons'/></a>
                        </div>
                    </>
                )}
            </div>
        )
    } else if (refer && (refer === refer_values.es)){
        return (
            <div className="container-bio">
                {((linkTreeEsp && linkTreeEsp.length > 0) && (linksEsp && linksEsp.length > 0)) && (
                    <>
                        <div className="bio-header"> 
                            <img src="aulart-logo-white-1.svg" alt="" className='bio-logo'/>
                        </div>
                        <div className="bio-titles">
                            <h1 className="bio-subtitle">{linkTreeEsp[0].title}</h1>
                            <div className="" style={{textAlign:'center'}}>
                                <a href="https://apps.apple.com/us/app/aulart/id6476809730"><img src="iosavailable.png" alt="Download App" style={{ height: '130px'}} /></a>
                            </div>
                            <p className="bio-paragraph">Descarga ahora y obtén Contenido Gratuito</p>
                        </div>
                        {user && <CreateLink language={'es'}/>}
                            <div className="links-bio">
                            {linksEsp.sort((a, b) => a.position - b.position).map((link, index) => (
                                    <>                                  
                                        <div className='link-bio'>
                                            {user && (
                                                <div className="link-actions">
                                                    <MdDeleteForever size={28} className='delete-icon' onClick={() => deleteLink(link.id)}/>
                                                    <AiFillEdit size={28} className='edit-icon' onClick={selectedLink !== index ? () => setSelectedLink(index) : () => setSelectedLink(null)}/>
                                                </div>
                                            )}
                                                <a href={`${link.link}?utm_medium=social&utm_source=${social ? social : 'error'}&utm_campaign=${link.utm}`} target="_blank">
                                                    <img className='image-bio-link' src={link.gcs?link.gcs:(HOST_URL +'images/'+ link.image)}  alt={link.text} />
                                                    {selectedLink === index ? (
                                                        <div className='edit-link-container'>
                                                            <input onClick={(e) => e.preventDefault()} id='text' name='text' value={text} onChange={onChange} type="text" className="input-form-center" placeholder={link.text} />
                                                            <input style={{maxWidth: '100px'}} onClick={(e) => e.preventDefault()} id='utm' name='utm' value={utm} onChange={onChange} type="text" className="input-form-center" placeholder={link.utm} />
                                                            <input onClick={(e) => e.preventDefault()} id='linkChange' name='linkChange' value={linkChange} onChange={onChange} type="text" className="input-form-center" placeholder={link.link} />
                                                            <input onClick={(e) => e.preventDefault()} style={{maxWidth: '50px'}} type='text' placeholder={link.position} id='position_id' name='position_id' value={position_id} onChange={onChange} className="input-form-center" required />
                                                            <button onClick={(e) => changeLinkTree(link.id, e)} type="submit" className="btn btn-reverse btn-title">SAVE</button>
                                                        </div>
                                                    ) : (
                                                        <p>{link.text}</p>
                                                    )}
                                                
                                            </a>
                                            
                                        </div>
                                    </>
                                ))}                          
                        </div>
                        <div className="social-media">
                            <a href="https://www.youtube.com/channel/UCL_R5xbrMzxB_9PlW4jyZFA" target="_blank"> <AiOutlineYoutube size={33} className='bio-icons'/></a>
                        </div>
                    </>
                )}
            </div>
        )
    } else {
        return (
            <>
            {!spanish ? (
                <div className="container-bio">
                {user && (
                    <div className="bio-header-buttons">
                        <Link to='/' style={{width: '150px', border: '1px solid white'}} className="btn btn-reverse">HOME</Link>
                        <button style={{marginTop: '15px', width: '150px', border: '1px solid white'}} onClick={() => setSpanish(!spanish)} className="btn btn-reverse">{spanish ? 'ENGLISH' : 'SPANISH'}</button>
                    </div>
                )}
                {(linkTreeEng && linkTreeEng.length > 0 && linksEng && linksEng.length > 0) && (
                    <>
                        <div className="bio-header"> 
                            <img src="aulart-logo-white-1.svg" alt="" className='bio-logo'/>
                        </div>
                        <div className="bio-titles">
                            <h1 className="bio-subtitle">{linkTreeEng[0].title}</h1>
                            
                            <div className="" style={{textAlign:'center'}}>
                                <a href="https://apps.apple.com/us/app/aulart/id6476809730"><img src="iosavailable.png" alt="Download App" style={{ height: '130px'}} /></a>
                            </div>
                            <p className="bio-paragraph">Dowload now to get Free Content</p>
                        </div>
                        {user && <CreateLink language={'eng'}/>}
                        <div className="links-bio">
                        {linksEng.sort((a, b) => a.position - b.position).map((link, index) => (
                                <>                                  
                                    <div className='link-bio'>
                                        {user && (
                                            <div className="link-actions">
                                                <MdDeleteForever size={28} className='delete-icon' onClick={() => deleteLink(link.id)}/>
                                                <AiFillEdit size={28} className='edit-icon' onClick={selectedLink !== index ? () => setSelectedLink(index) : () => setSelectedLink(null)}/>
                                            </div>
                                        )}
                                            <a href={`${link.link}?utm_medium=social&utm_source=${social ? social : 'error'}&utm_campaign=${link.utm}`} target="_blank">
                                                <img className='image-bio-link' src={link.gcs?link.gcs:(HOST_URL +'images/'+ link.image)}  alt={link.text} />
                                                {selectedLink === index ? (
                                                    <div className='edit-link-container'>
                                                        <input onClick={(e) => e.preventDefault()} id='text' name='text' value={text} onChange={onChange} type="text" className="input-form-center" placeholder={link.text} />
                                                        <input style={{maxWidth: '100px'}} onClick={(e) => e.preventDefault()} id='utm' name='utm' value={utm} onChange={onChange} type="text" className="input-form-center" placeholder={link.utm} />
                                                        <input onClick={(e) => e.preventDefault()} id='linkChange' name='linkChange' value={linkChange} onChange={onChange} type="text" className="input-form-center" placeholder={link.link} />
                                                        <input onClick={(e) => e.preventDefault()} style={{maxWidth: '50px'}} type='text' placeholder={link.position} id='position_id' name='position_id' value={position_id} onChange={onChange} className="input-form-center" required />
                                                        <button onClick={(e) => changeLinkTree(link.id, e)} type="submit" className="btn btn-reverse btn-title">SAVE</button>
                                                    </div>
                                                ) : (
                                                    <p>{link.text}</p>
                                                )}
                                            
                                        </a>
                                        
                                    </div>
                                </>
                            ))}                             
                        </div>
                        <div className="social-media">
                            <a href="https://www.youtube.com/channel/UCL_R5xbrMzxB_9PlW4jyZFA" target="_blank"> <AiOutlineYoutube size={33} className='bio-icons'/></a>
                        </div>
                    </>
                )}
            </div>

            ) : (
                <div className="container-bio">
                     {user && (
                    <div className="bio-header-buttons">
                        <Link to='/' style={{width: '150px', border: '1px solid white'}}  className="btn btn-reverse">HOME</Link>
                        <button style={{marginTop: '15px', width: '150px', border: '1px solid white'}} onClick={() => setSpanish(!spanish)} className="btn btn-reverse">{spanish ? 'ENGLISH' : 'SPANISH'}</button>
                    </div>
                )}
                {(linkTreeEsp && linkTreeEsp.length > 0 && linksEsp && linksEsp.length > 0) && (
                    
                    <>
                        <div className="bio-header"> 
                            <img src="aulart-logo-white-1.svg" alt="Aulart" className='bio-logo'/>
                        </div>
                        <div className="bio-titles">
                            <h1 className="bio-subtitle">{linkTreeEsp[0].title}</h1>
                            <div className="" style={{textAlign:'center'}}>
                                <a href="https://apps.apple.com/us/app/aulart/id6476809730"><img src="iosavailable.png" alt="Download App" style={{ height: '130px'}} /></a>
                            </div>
                            <p className="bio-paragraph">Descarga ahora y obtén Contenido Gratuito</p>
                        </div>
                        {user && <CreateLink language={'es'}/>}
                            <div className="links-bio">
                            {linksEsp.sort((a, b) => a.position - b.position).map((link, index) => (
                                    <>                                  
                                        <div className='link-bio'>
                                            {user && (
                                                <div className="link-actions">
                                                    <MdDeleteForever size={28} className='delete-icon' onClick={() => deleteLink(link.id)}/>
                                                    <AiFillEdit size={28} className='edit-icon' onClick={selectedLink !== index ? () => setSelectedLink(index) : () => setSelectedLink(null)}/>
                                                </div>
                                            )}
                                                <a href={`${link.link}?utm_medium=social&utm_source=${social ? social : 'error'}&utm_campaign=${link.utm}`} target="_blank">
                                                    <img className='image-bio-link' src={link.gcs?link.gcs:(HOST_URL +'images/'+ link.image)}  alt={link.text} />
                                                    {selectedLink === index ? (
                                                        <div className='edit-link-container'>
                                                            <input onClick={(e) => e.preventDefault()} id='text' name='text' value={text} onChange={onChange} type="text" className="input-form-center" placeholder={link.text} />
                                                            <input style={{maxWidth: '100px'}} onClick={(e) => e.preventDefault()} id='utm' name='utm' value={utm} onChange={onChange} type="text" className="input-form-center" placeholder={link.utm} />
                                                            <input onClick={(e) => e.preventDefault()} id='linkChange' name='linkChange' value={linkChange} onChange={onChange} type="text" className="input-form-center" placeholder={link.link} />
                                                            <input onClick={(e) => e.preventDefault()} style={{maxWidth: '50px'}} type='text' placeholder={link.position} id='position_id' name='position_id' value={position_id} onChange={onChange} className="input-form-center" required />
                                                            <button onClick={(e) => changeLinkTree(link.id, e)} type="submit" className="btn btn-reverse btn-title">SAVE</button>
                                                        </div>
                                                    ) : (
                                                        <p>{link.text}</p>
                                                    )}
                                                
                                            </a>
                                            
                                        </div>
                                    </>
                                ))}                          
                        </div>
                        <div className="social-media">
                            <a href="https://www.youtube.com/channel/UCL_R5xbrMzxB_9PlW4jyZFA" target="_blank"> <AiOutlineYoutube size={33} className='bio-icons'/></a>
                        </div>
                    </>
                )}
            </div>
            )}
            </>
        )
    }
        
}

export default LinkInBio