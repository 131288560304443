import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom'
import { useEffect, useSelector } from 'react';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Header from './components/headers/Header'
import Home from './pages/home/Home';
import { auth } from './firebaseConfig';
import { useDispatch } from 'react-redux';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getUserState, setUser} from './features/auth/authSlice';

import PrivateRoutes from './PrivateRoutes'

import LinkInBio from './pages/linktree/LinkInBio';


function App() {

  const location = useLocation();
  const shouldRenderHeaderAndNavBar = !location.pathname.includes('/linkinbio');

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // Pass only the necessary user data
        dispatch(setUser({
          uid: user.uid,
          email: user.email,
          token: user.accessToken,
          // ... any other fields you need
        }));
      } else {
        dispatch(setUser(null)); // No user is signed in
      }
    });
  
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);
  


  return (
   <>
    {shouldRenderHeaderAndNavBar && (
            <Header />
        )}
      <Routes>
          <Route path='/:param' element={<LinkInBio />} />
          <Route path='/' element={<LinkInBio />} />
          <Route path='/login' element={<Login />} />
          {/* <Route path='/register' element={<PrivateRoutes />}> */}
            <Route path='/register' element={<Register />} />
          {/* </Route> */}
          {/* <Route path='/linkinbio' element={<LinkInBio />} /> */}
      </Routes>
      <ToastContainer />
   </>
  );
}

export default App;