import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {createLink, getLinkTreeData, getSignedUrl} from '../../features/linkTreeSlice';
import { toast } from 'react-toastify';



function CreateLink({language}) {

    const [display, setDisplay] = useState(false);
    const [position, setPosition] = useState('');
    const {user} = useSelector ((state) => state.auth);

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        text: '',
        link: '',
        utm: '',
        image: null,
    });

    const { text, link, utm, image } = formData;

    const onChangeImage = (e) => {
        const file = e.target.files[0];
        setFormData((prevState) => ({
          ...prevState,
          image: file,
        }));
      };
      

      const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      };

      const onChangePosition = (e) => {
        setPosition(e.target.value);
      };
      

      const newLink = (linkData) => {
          

        dispatch(createLink(linkData))
          .unwrap()
          .then(() => {
            dispatch(getLinkTreeData())
            setDisplay(false)
            setFormData({
              text: '',
              link: '',
              utm: '',
              image: null,
            });
          })
          .catch((error) => {
            toast.error(error)
          })
      
      }

      const onSubmit = (e) => {
        e.preventDefault();
        const linkImage = new File([image], image.name, { type: image.type })

        const data_sign= {
          filename : linkImage.name,
          filetype : linkImage.type,
          folder_name : 'linktree',
        }


       
       dispatch(getSignedUrl(data_sign))
        .unwrap()
        .then((response) => {
          console.log(response)
          if(!response.success){alert('error')};

          const xhr = new XMLHttpRequest();
          xhr.open("PUT", response.url, true);
          xhr.onload = () => {
          const status = xhr.status;
          console.log(xhr);

          
          if (status === 200) {
              // alert("File is uploaded");
              // setLoadingMessage(`File uploaded`);
              const urlfile = '/linktree/'+linkImage.name;
              linkImage.url = urlfile;
              const linkData = {
                  section: 'link',
                  language: language,
                  text,
                  link,
                  utm,
                  position,
                  linkImage,
              }
            
              newLink(linkData);

          } else {
              alert(`Error status: ${status}`);
              // setLoading(false);
          }
          };

          xhr.upload.addEventListener("progress", (event) => {
              if (event.lengthComputable) {
                  const percentage = Math.round((event.loaded * 100) / event.total);
                  console.log(`Subida: ${percentage}%`);
                  // setLoadingMessage(`Uploading file... ${percentage}%`);
              }
          });

          xhr.onerror = (error) => {
              alert(error);
              // setLoading(false);
          };
          xhr.setRequestHeader('Content-Type', linkImage.type);
          xhr.send(linkImage)
        });


       
      
      }

      if(user){
        return (
          <>
              <button className="btn btn-reverse btn-links" onClick={() => setDisplay(!display)}> ADD LINK </button>
              {display && (
                  <section className='form'>
                  <form onSubmit={onSubmit}>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        id='text'
                        name='text'
                        value={text}
                        onChange={onChange}
                        placeholder='Enter description'
                        required
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        id='link'
                        name='link'
                        value={link}
                        onChange={onChange}
                        placeholder='Add link: https://www.aulart.com/landings/membership-starter/'
                        required
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control'
                        id='utm'
                        name='utm'
                        value={utm}
                        onChange={onChange}
                        placeholder='Enter campaign name: starter'
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input type='text' placeholder='Set Link position' id='position' name='position' value={position} onChange={onChangePosition} className='form-control' required />
                    </div>
                    <div className="form-group">
                      <input
                        type='file'
                        alt='add image'
                        className='form-control'
                        id='image'
                        name='image'
                        accept="image/jpeg, image/png"
                        onChange={onChangeImage}
                        required
                      />
                    </div>
  
                    <div className='form-group'>
                      <button className='btn btn-block'>Submit</button>
                    </div>
                  </form>
                  </section>
              )}
          </>
      )
      }
}

export default CreateLink