import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {logout, reset} from '../../features/auth/authSlice'
import { useEffect, useState} from 'react'
import {AiOutlineHome} from 'react-icons/ai'


function Header() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const inRootPath = location.pathname != '/';
  console.log(inRootPath);

  const { user} = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  
if(inRootPath){
  return (<></>)
}


return (
    <header className='header'>
  <div className='left-nav'>
    {user && user.role === 'video' ? (
      <Link to='/videos/home'><AiOutlineHome size={30}/></Link>
      ) : (
    user && user.role === 'marketing'? <Link to='/marketing/home'><AiOutlineHome size={30}/></Link> 
    : user && user.role === 'admin'? <Link to='/'><AiOutlineHome size={30}/></Link>  
    : null
     )}

  </div>
  
  <ul>
    {user ? (
      <>
      <li>
        <button className='btn' onClick={onLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </li>
      </>
 ) : ( 
    <> 
        {/* <li>
          <Link to='/login'>
            <FaSignInAlt /> Login
          </Link>
        </li> */}
        {/* <li>
          <Link to='/register'>
            <FaUser /> Register
          </Link>
        </li> */}
       </>
    )} 
  </ul>
</header>
    
    )
}

export default Header