import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset} from '../../features/auth/authSlice';
import Spinner from '../../components/loaders/Spinner'

function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changePassword, setReset] = useState(false);

    const { isLoading, user } = useSelector((state) => state.auth);


    const [formData, setFormData] = useState({
        email: '',
        password: '',
      })
    
      const { email, password } = formData
    
    
      const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
      }

      const onSubmit = (e) => {
        e.preventDefault()
    
        const userData = {
          email,
          password,
        }

        const data = {
          email,
        }
        if(!changePassword){
            dispatch(login(userData))
            .unwrap()
            .then(() => {
              toast.success(`Logged in`)
              navigate('/')
            })
            .catch((error) => {
              toast.error(error)
            })
            .then(() => {
                dispatch(reset());
            });
      
        
      }

    }

      if(isLoading){
        return(
          <Spinner/>
        )
      }

      return (
        <>
          <section className='heading'>
            <h1>

            {!changePassword ? 'Login Link in bio' : 'Reset password'}
              
            </h1>
          </section>
    
          <section className='form'>
            <form onSubmit={onSubmit}>
              <div className='form-group'>
                <input
                  type='email'
                  className='form-control'
                  id='email'
                  name='email'
                  value={email}
                  onChange={onChange}
                  placeholder='Enter your email'
                  required
                />
              </div>
              {!changePassword ? (
                <div className='form-group'>
                  <input
                    type='password'
                    className='form-control'
                    id='password'
                    name='password'
                    value={password}
                    onChange={onChange}
                    placeholder='Enter password'
                    required
                  />
              </div>
              ): null}
              
              <p style={{cursor: 'pointer'}} onClick={() => setReset(!changePassword)} className='center-element' to="/password"> {!changePassword ? 'Forgot your password?' : 'Back to login'}</p>
              <div className='form-group center-element'>
                <button className='btn btn-block center-element'>{!changePassword ? 'Submit' : 'Send email'}</button>
              </div>
            </form>
          </section>
        </>
      )
    }
    
    export default Login


