// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCL7nDwW7GQiF2c8Ih1XGbZJ1spX-AlovE',
  authDomain: 'aulart-tools.firebaseapp.com',
  projectId: 'aulart-tools',
  storageBucket: 'aulart-tools.appspot.com',
  messagingSenderId: '1021377235496',
  appId: '1:1021377235496:web:6248e9fcfe9207cab9c31d',
};
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_AUTH_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_AUTH_BUCKET,
//   messagingSenderId: process.env.REACT_APP_AUTH_SENDER_ID,
//   appId: process.env.REACT_APP_AUTH_APP_ID,
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { app, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword };