import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '../../firebaseConfig';

export const register = (user) => {
  return createUserWithEmailAndPassword(auth, user.email, user.password);
};

export const login = (user) => {
  return signInWithEmailAndPassword(auth, user.email, user.password);
};

export const logout = () => {
  return auth.signOut();
};

const authService = {
  register,
  logout,
  login,
}

export default authService;
